<template>
  <!-- 
    `v-mask="'##:##'"`
    This is required to format the entry value to
    `2 digits` + `:` + `2 digits`.

    `@input`
    This event is a limitation for 
    Android-For-Chrome since this browser
    does NOT support @keyup or @keydown.

    This input can NOT simply be of `type="time"`
    because this attribute limit the entry value
    to a max of 24 hours. We need to be allowed 
    to enter more then 24:01 if we want to.
    -->
  <wx-text-field
    v-model.trim="duration"
    v-mask="'##:##'"
    @input="handleInputChange($event)"
    :wxid="$options.name"
    :label="title"
    :disabled="disabled"
    :rules="rules"
    :error-messages="validationErrors"
    :class="getValidationErrorsClass()"
    class="wx-timer-duration-input append-icon"
    append-icon="mdi-timer-outline"
  />
</template>

<script>
import WxTextField from "@/components/ui/WxTextField";
export default {
  name: "WxTimerDurationInput",
  components: {
    WxTextField,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    errorMessages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      duration: this.value,
      previousDuration: this.value,
      validationErrors: this.errorMessages,
    };
  },
  watch: {
    value() {
      this.duration = this.value;
    },
    errorMessages() {
      this.validationErrors = this.errorMessages;
    },
  },
  methods: {
    handleInputChange() {
      this.emitDuration();
    },
    getValidationErrorsClass() {
      if (this.validationErrors && this.validationErrors.length > 0) {
        return "error--text";
      }
      return "";
    },
    emitDuration() {
      this.$emit("input", this.duration);
      this.$emit("newDuration", this.duration);
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-timer-duration-input {
  gap: 10px;
}
</style>
